import React, { useRef, useEffect } from "react";

//Components
import Layout from "../../components/Layout";
import Categories from "../../components/Categories";
import Brands from "../../components/Brands";

const Products = () => {
  return (
    <Layout>
      <section className="categories">
        <Categories />
      </section>
      <section className="brands">
        <Brands />
      </section>
    </Layout>
  );
};

export default Products;
