import React from "react";
import {
  brandsContainer,
  brandsItemsContainer,
  titleHeader,
  brandContainer,
  imageWrapper,
} from "./brands.module.css";
import { StaticImage } from "gatsby-plugin-image";

const TitleHeader = ({ children }) => {
  return <h2 className={titleHeader}>{children}</h2>;
};
const Brand = ({ Image }) => {
  return (
    <div className={brandContainer}>
      <div className={imageWrapper}>{Image}</div>
    </div>
  );
};

const Brands = () => {
  return (
    <div className={brandsContainer}>
      <TitleHeader>Marcas</TitleHeader>
      <div className={brandsItemsContainer}>
        <Brand
          Image={
            <StaticImage
              alt="brand image"
              src="../../images/brand1.png"
              layout="constrained"
            />
          }
        />
        <Brand
          Image={
            <StaticImage
              alt="brand image"
              src="../../images/brand2.png"
              layout="constrained"
            />
          }
        />
        <Brand
          Image={
            <StaticImage
              alt="brand image"
              src="../../images/brand3.png"
              layout="constrained"
            />
          }
        />
        <Brand
          Image={
            <StaticImage
              alt="brand image"
              src="../../images/brand4.png"
              layout="constrained"
            />
          }
        />
        <Brand
          Image={
            <StaticImage
              alt="brand image"
              src="../../images/brand5.png"
              layout="constrained"
            />
          }
        />
        <Brand
          Image={
            <StaticImage
              alt="brand image"
              src="../../images/brand6.png"
              layout="constrained"
            />
          }
        />
        <Brand
          Image={
            <StaticImage
              alt="brand image"
              src="../../images/brand7.png"
              layout="constrained"
            />
          }
        />
        <Brand
          Image={
            <StaticImage
              alt="brand image"
              src="../../images/brand8.png"
              layout="constrained"
            />
          }
        />
        <Brand
          Image={
            <StaticImage
              alt="brand image"
              src="../../images/brand9.png"
              layout="constrained"
            />
          }
        />
        <Brand
          Image={
            <StaticImage
              alt="brand image"
              src="../../images/brand10.png"
              layout="constrained"
            />
          }
        />
      </div>
    </div>
  );
};

export default Brands;
