import React from "react";
import {
  categoriesContainer,
  categoriesItemsContainer,
  titleHeader,
  cardContainer,
  cardInner,
  cardCircle,
  cardImageWrapper,
  cardCategorieTitle,
} from "./categories.module.css";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import cat1Img from "../../images/cat1.jpg";

const TitleHeader = ({ children }) => {
  return <h2 className={titleHeader}>{children}</h2>;
};
const Card = ({ title, Image }) => {
  return (
    <div className={cardContainer}>
      <div className={cardInner}>
        <div className={cardCircle}>
          <div className={cardImageWrapper}>{Image}</div>
        </div>
        <h3 className={cardCategorieTitle}>{title}</h3>
      </div>
    </div>
  );
};

const Categories = () => {
  return (
    <div className={categoriesContainer}>
      <TitleHeader>Categorias</TitleHeader>
      <div className={categoriesItemsContainer}>
        <Link
          to="/productos/equipo-medico"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Card
            title="Equipo medico hospitalario"
            Image={
              <StaticImage
                alt="category image"
                src="../../images//cat1.jpg"
                height="100%"
                layout="constrained"
                style={{ height: "100%" }}
              />
            }
          />
        </Link>
        <Link
          to="/productos/equipo-extra-hospitalario"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Card
            title="Equipo medico extra hospitalario"
            Image={
              <StaticImage
                alt="category image"
                src="../../images//cat2.jpg"
                height="100%"
                layout="constrained"
                style={{ height: "100%" }}
              />
            }
          />
        </Link>
        <Link
          to="/productos/proyectos-integrales"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Card
            title="Proyectos integrales/Servicios de mantenimiento"
            Image={
              <StaticImage
                alt="category image"
                src="../../images//cat3.jpg"
                height="100%"
                layout="constrained"
                style={{ height: "100%" }}
              />
            }
          />
        </Link>
        <Link
          to="/productos/dispositivos-medicos"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Card
            title="Dispositivos médicos por especialidad"
            Image={
              <StaticImage
                alt="category image"
                src="../../images//cat4.jpg"
                height="100%"
                layout="constrained"
                style={{ height: "100%" }}
              />
            }
          />
        </Link>
        <Link
          to="/productos/laboratorio"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Card
            title="Laboratorio"
            Image={
              <StaticImage
                alt="category image"
                src="../../images//cat5.jpg"
                height="100%"
                layout="constrained"
                style={{ height: "100%" }}
              />
            }
          />
        </Link>
        <Link
          to="/productos/instrumental"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Card
            title="Instrumental"
            Image={
              <StaticImage
                alt="category image"
                src="../../images//cat6.jpg"
                height="100%"
                layout="constrained"
                style={{ height: "100%" }}
              />
            }
          />
        </Link>
      </div>
    </div>
  );
};

export default Categories;
